import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PageLayout from "../components/page-layout"
import Message from "../components/message"
import Lesson from "../components/lesson-table"
import Schedule from "../components/schedule-table"
import FeeTable from "../components/fee-table"

import Theme from "../images/child-class-top.jpg"

export default function Home() {
    let data = useStaticQuery(graphql`
    query allChildJson {
        allLessonsJson(filter: {for: {eq: "child"}}) {
            edges {
                node {
                    time
                    name
                    week
                    row
                    color
                    grade
                  }            
            }
        }
        allFeeJson(filter: {for: {eq: "child"}}) {
            edges {
                node {
                    title1
                    title2
                    title3
                    type
                    fee
                    count
                }
            } 
        }
    }
    `);

    return (
        <PageLayout title="子どもクラス" subTitle={`初めの一歩をここから`} image={Theme} pageName="child">
            <Message>
                {`当スタジオは、小さなお教室型のバレエスタジオです。
                幼稚園児から小学校１〜２年生までの間は、できる限り同じ先生が一貫性のある指導をすることが望ましいと考えています。
                小さい間は心の成長を重視し、安定した環境でレッスンをすることをお勧めいたします。
                よく見る、よく聴く、自分で考えてやってみる、という３つの力が身に付けば、その後のレッスンでもその他の生活面でも大きく成長してくれるでしょう。
                成長には個人差がありますので、根気よく個性を否定せずに、長所を伸ばすよう指導にあたります。
                        
                お稽古の先生というのは、学校の先生よりもずっと長いお付き合いになることがあります。
                お互いに信頼関係を築いていけるよう、心配なことがある場合にはご相談ください。`}
            </Message>
            <br />
            <div class="message">
                <a href="/voice/#child">保護者さまの声はこちら＞＞＞</a>
            </div>

            <h3>子どもクラス　スケジュール</h3>

            <Schedule data={data} />

            <Message>
                {`Jazzクラスは宝塚受験希望者以外の方も受講できます。
                担当講師は、見学・体験レッスン時にお問い合わせください。`}
            </Message>

            <Lesson title="プライマリークラス（バレエの準備クラス）">
                {`プライマリー B
                --
                ３〜６歳（年少〜年長児）

                クラス定員８名
                柔軟性、音楽性、表現力、姿勢やお稽古のマナーなどをバレエの基本動作に沿いながらゆっくり学びます。
                ---
                プライマリー A
                --
                ７〜８歳（小学校１年生〜２年生）
            
                クラス定員１０名
                バレエの基礎　ポジションや基本のバーレッスン、センターレッスンを少しずつ学びます。
                希望者は週２回レッスンとなります。`}
            </Lesson>
            <Lesson title="ジュニアクラス">
                {`ジュニア C
                --
                ９〜１１歳（小学校３年生〜５年生）

                クラス定員１２名。
                週２回になり、基礎レッスンに加えてセンターレッスンが増え、トウシューズをはく準備を始めます。
                トウシューズレッスンは、個々の体格や筋力に合わせて、許可制となります。
                希望者は週３回レッスンとなります。
                ---
                ジュニア B
                --
                １０〜１４歳（小学校４年生〜中学校２年生）
                週３回になりトウシューズのレッスンが本格的に始まります。
                中学校受験期は週１〜２回にすることができます。
                ---
                ジュニア A
                --
                １３〜１８歳（レベルに達した中学校１年生〜高校３年生）
                基礎レッスンの他、テクニックに加えて表現力を学びます。
                希望者はヴァリエーションレッスンを受けることができます。
                宝塚音楽学校受験生は受験準備が始まります。`}
            </Lesson>

            <h3>子どもクラス　お月謝・料金表</h3>

            <FeeTable data={data} />

            <Message>
                {`
                お休みした場合、他の日に振替レッスンができます。(レッスン細則をご確認ください。)
                ＊維持費は、コロナ等感染症対策衛生費及び冷暖房費として、１ヶ月につき1,100円を頂戴いたします。
                `}
            </Message>

            <div className="letter">
                <h4>バレエ教室を探していらっしゃる保護者の皆様へ</h4>
                <Message>
                    {`
                    私がバレエを始めたのは、小学校2年生の時でした。
                    小さな集会所で見かけたレッスンに一人釘付けになり、家に帰って母に懇願して習わせてもらったのです。
                    それまでの私は、授業中に手を挙げることもできない、人前で意見を言うなどとんでもないというような、とても内気な子でした。
                    失敗するのが怖かったからです。
                    私がバレエ教室で得た最高の学びは、「失敗をしても良い」ということです。
                    何度も繰り返して練習し、先生がそれを否定せずにずっと見守って下さった経験が大きな自信となりました。
                    最初はうまくいかなくても、続けていればだんだんできるようになる、それを実践して気づきを得たことは私の人生において大きな意味を持ちました。
                    当時夢見たバレリーナにはなれませんでしたが、やがて大きくなった私は宝塚歌劇団に入団しました。
                    クラシックバレエを習得していたことが、他のダンスに出会った時にとても役に立ったと思います。
                    今では私も子育てを経験した一人の母親です。
                    子どもの成長には個人差があること、他の子と比較するのは必ずしも正しい基準ではないこと、習い事は楽しくなければ続かないことを経験を通してよく理解しています。
                    このスタジオが家庭と学校（幼稚園）以外の心許せる居場所になり、多くの経験を得る場であってほしいと願っています。
                    `}
                </Message>
            </div>
        </PageLayout >
    )
}